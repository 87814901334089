@tailwind components;

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

div#wave {
	position:relative;
	.dot {
		display:inline-block;
		width:3px;
		height:3px;
		border-radius:50%;
		margin-right:4px;
		background:#303131;
		animation: wave 1s linear infinite;

		&:nth-child(1) {
			animation-delay: -0.7s;
		}

		&:nth-child(2) {
			animation-delay: -0.9s;
		}

		&:nth-child(3) {
			animation-delay: -1.1s;
		}
	}
}

@keyframes wave {
	0%, 40%, 100% {
		transform: initial;
	}

	20% {
		transform: translateY(-5px);
	}
}
