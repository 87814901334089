@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: '__myFont_84aeb9';
  font-weight: 300;
}

/* handle editable content placeholder */
[contentEditable='true']:empty:before {
  content: attr(placeholder);
  opacity: 0.6;
}

.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  -ms-overflow-style: none; /* IE and Edge */
}

.truncate-product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.6;
}

.truncate-legal-mentions {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@import './components.css';
